import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import messages from '../../messages'

const ActionButtons = React.memo(props => {
  const {
    intl: { formatMessage },
    isGpsTrackingsLoading,
    isInvalidGpsTrackingDateRange,
    isMenuOpen,
    isRealtimeGpsTrackingRunning,
    isRealtimeTrackingSourceSelected,
    onHistoricalGpsTrackingSearchClick,
    onRealtimeGpsTrackingStartClick,
    onRealtimeGpsTrackingStopClick,
    onResetButtonClick,
    selectedDevices,
    onDownloadCSVButtonClick,
    queriedDevices
  } = props

  let mainActionButton = null
  if (isRealtimeTrackingSourceSelected) {
    if (isRealtimeGpsTrackingRunning) {
      mainActionButton = (
        <Button className='primary-action-button' fullWidth onClick={onRealtimeGpsTrackingStopClick}>
          {formatMessage(messages.stop)}
        </Button>
      )
    } else {
      mainActionButton = (
        <Button
          className='primary-action-button'
          disabled={selectedDevices.length === 0}
          fullWidth
          onClick={onRealtimeGpsTrackingStartClick}
        >
          {formatMessage(messages.start)}
        </Button>
      )
    }
  } else {
    mainActionButton = (
      <Button
        className='primary-action-button'
        disabled={selectedDevices.length === 0 || isGpsTrackingsLoading || isInvalidGpsTrackingDateRange}
        fullWidth
        onClick={onHistoricalGpsTrackingSearchClick}
      >
        {isGpsTrackingsLoading ? <CircularProgress size={22} /> : formatMessage(messages.search)}
      </Button>
    )
  }

  const resetButton = (
    <Button className='secondary-action-button' fullWidth onClick={onResetButtonClick}>
      {formatMessage(messages.reset)}
    </Button>
  )

  const downloadCSVButton = (
    <Button
      className='secondary-action-button'
      disabled={
        queriedDevices.length === 0 ||
        selectedDevices.length === 0 ||
        isGpsTrackingsLoading ||
        isInvalidGpsTrackingDateRange
      }
      fullWidth
      onClick={onDownloadCSVButtonClick}
    >
      {formatMessage(messages.downloadCSV)}
    </Button>
  )

  return (
    <div
      style={{
        bottom: 15,
        display: isMenuOpen ? 'block' : 'none',
        position: 'absolute',
        width: '97%'
      }}
    >
      <Grid alignItems='center' container spacing={1} style={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <Grid className='text-right' item xs={6}>
          {resetButton}
        </Grid>
        <Grid className='text-right' item xs={6}>
          {downloadCSVButton}
        </Grid>
        <Grid className='text-right' item xs={12}>
          {mainActionButton}
        </Grid>
      </Grid>
    </div>
  )
})

ActionButtons.propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func.isRequired }).isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isInvalidGpsTrackingDateRange: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  isRealtimeTrackingSourceSelected: PropTypes.bool.isRequired,
  onDownloadCSVButtonClick: PropTypes.func.isRequired,
  onHistoricalGpsTrackingSearchClick: PropTypes.func.isRequired,
  onRealtimeGpsTrackingStartClick: PropTypes.func.isRequired,
  onRealtimeGpsTrackingStopClick: PropTypes.func.isRequired,
  onResetButtonClick: PropTypes.func.isRequired,
  queriedDevices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired
}

export default injectIntl(ActionButtons)
