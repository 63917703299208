import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { actions as geotrackingActions, selectors as geotrackingSelectors } from 'ducks/geotracking'
import { utils as groupUtils } from 'ducks/groups'
import { actions as websocketActions } from 'ducks/websocket'
import csNode from 'modules/csNode'
import * as cp from 'utils/constants'

import QueryPanel from './QueryPanel'

const mapStateToProps = state => ({
  nodeCredentials: csNode.utils.getRealTimeCredentials(state),
  groupId: groupUtils.getSelectedGroup(state).id,
  isGpsTrackingsLoading: geotrackingSelectors.getIsGpsTrackingsLoading(state),
  privileges: {
    canViewGeofence: groupUtils.hasPermission(state, cp.GEOFENCE_READ)
  },
  gpsTrackings: geotrackingSelectors.getGPSTrackings(state)
})

const mapDispatchToProps = dispatchFunc =>
  bindActionCreators(
    {
      connectRealtimeGPSWebS: (nodeId, topic, wscredentials) => dispatch => {
        dispatch(websocketActions.subscribeGPS(topic, nodeId, wscredentials))
      },
      deleteGPSTrackings: () => dispatch => {
        dispatch(geotrackingActions.deleteGPSTrackings())
      },
      disconnectRealtimeGPSWebS: (nodeId, topic) => dispatch => {
        dispatch(websocketActions.unsubscribe(topic, nodeId))
      },
      getCSNodes: (groupId, limit, offset, deviceFields) => dispatch => {
        return dispatch(csNode.actions.fetchDynamicCSNodes(groupId, limit, offset, deviceFields))
      },
      getGPSTrackingTimeSeries: (nodeId, start, end) => dispatch => {
        dispatch(geotrackingActions.addGpsTrackingsLoading(nodeId))
        const { promise } = dispatch(geotrackingActions.getGPSTrackingTimeSeries(nodeId, start, end))
        return promise
      }
    },
    dispatchFunc
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QueryPanel))
