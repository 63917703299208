import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { createNodesLoader } from 'components/LoadNodesOnMount'
import { selectors as geotrackingSelectors } from 'ducks/geotracking'
import { utils, actions } from 'ducks/map'
import * as map from 'ducks/map'

import Geotracking from './Geotracking'

const mapStateToProps = state => ({
  mergedAndOrderedGpsTrackings: geotrackingSelectors.getGPSTrackingsMergedAndOrdered(state),
  currentMapCenter: utils.getCurrentMapCenter(state),
  currentZoom: utils.getCurrentZoom(state),
})

const mapDispatchToProps = dispatchFunc =>
  bindActionCreators(
    {
      load: () => dispatch => {
        dispatch(map.actions.show())
      },
      unload: map.actions.hide,
      setCenter: actions.setCenter,
      setZoomLevel: actions.setZoomLevel
    },
    dispatchFunc
  )

export default connect(mapStateToProps, mapDispatchToProps)(createNodesLoader(Geotracking))
