import _isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { Map } from 'components/Map'

import GpsInfoPopup from './GpsInfoPopup'
import LoadingGps from './LoadingGps'
import MarkersAndLines from './MarkersAndLines'
import NoGpsTrackingAlert from './NoGpsTrackingAlert'
import TimelinePanel from './TimelinePanel'
import { cleanEID } from '../../../utils'

const styles = {
  loadingWrapper: {
    background: '#f9f9f9',
    display: 'flex',
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    zIndex: 10
  },
  mapContainer: {
    height: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    flexGrow: 3
  },
  map: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    height: '100%',
    width: '100%'
  }
}

class MapView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMapLoaded: false,
      popupInfo: null,
      timelinePanelContainerHeight: 15
    }

    this.defaultCenter = { latitude: 43.295645, longitude: -1.980012 }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState)
  }

  componentDidUpdate(prevProps) {
    const { gpsTrackings } = this.props
    const { gpsTrackings: prevGpsTrackings } = prevProps

    const isAllGpsPointsReady =
      Object.values(gpsTrackings).length > 0 && Object.values(gpsTrackings).every(item => item.ready)
    const wereAllGpsPointsReady =
      Object.values(prevGpsTrackings).length > 0 && Object.values(prevGpsTrackings).every(item => item.ready)

    if (!wereAllGpsPointsReady && isAllGpsPointsReady) {
      this.fitAllGpsPointsOnMap()
    }
  }

  handleSetTimelinePanelContainerHeight = containerHeight => {
    this.setState({
      timelinePanelContainerHeight: containerHeight
    })
  }

  getChartData = chartPoints => {
    const { getChartPointLimits, gpsTrackings, queriedDevices } = this.props

    const queriedDevicesWithGpsTrackings = queriedDevices.filter(device =>
      Object.keys(gpsTrackings).includes(cleanEID(device.EID))
    )

    const { from, to } = getChartPointLimits(chartPoints)

    const { series, chartCategories } = queriedDevicesWithGpsTrackings.reduce(
      (acc, device, index) => {
        const deviceChartPoints = chartPoints.filter(point => point.deviceEID === cleanEID(device.EID))
        const serieColor = deviceChartPoints?.[0]?.color
        const serieId = deviceChartPoints?.[0]?.deviceEID

        const newSerie = {
          id: serieId,
          data: deviceChartPoints.map(point => [point.x, index]),
          color: serieColor
        }
        const newChartCategory = device.name

        return {
          series: [...acc.series, newSerie],
          chartCategories: [...acc.chartCategories, newChartCategory]
        }
      },
      { series: [], chartCategories: [] }
    )

    const chartData = {
      series,
      yAxis: {
        categories: chartCategories,
        reversed: true
      },
      xAxis: {
        plotBands: [
          {
            color: '#E0E0E0',
            from,
            to
          }
        ]
      }
    }

    return chartData
  }

  fitAllGpsPointsOnMap = () => {
    const { gpsTrackings, onFlyToMarkerClick } = this.props
    const { isMapLoaded } = this.state

    const deviceEIDsWithGpsTrackings = Object.keys(gpsTrackings)
    const isAllGpsPointsReady =
      deviceEIDsWithGpsTrackings.length > 0 &&
      deviceEIDsWithGpsTrackings.every(deviceEID => gpsTrackings[deviceEID].ready)

    if (isMapLoaded && isAllGpsPointsReady) {
      const allGpsPoints = deviceEIDsWithGpsTrackings.reduce((ret, deviceEID) => {
        let currentKeysPoints = []
        if (Array.isArray(gpsTrackings[deviceEID].points)) {
          currentKeysPoints = gpsTrackings[deviceEID].points
        }
        return [...ret, ...currentKeysPoints]
      }, [])

      onFlyToMarkerClick(allGpsPoints)
    }
  }

  handleMapLoad = () => {
    this.setState({ isMapLoaded: true })
  }

  handleMarkerClick = (device, gpsPoint) => {
    const { deviceColors } = this.props

    const { color } = deviceColors.find(deviceColor => deviceColor.EID === device.EID) || {}

    this.setState({ popupInfo: { ...device, color, position: gpsPoint } })
  }

  handlePopupClose = () => {
    this.setState({ popupInfo: null })
  }

  renderPopup = () => {
    const { popupInfo } = this.state
    if (popupInfo) {
      return <GpsInfoPopup onPopupClose={this.handlePopupClose} popupInfo={popupInfo} />
    }
  }

  render() {
    const {
      classes,
      currentPosition,
      currentZoom,
      deviceColors,
      getChartPointLimits,
      getChartPoints,
      getFilteredAndOrderedGpsPoints,
      gpsTrackings,
      isGpsTrackingsLoading,
      isRealtimeGpsTrackingRunning,
      mapContainerRef,
      onStepChange,
      queriedDevices,
      selectedDevices,
      step,
      trail
    } = this.props
    const { isMapLoaded, timelinePanelContainerHeight } = this.state

    const deviceEIDsWithGpsTrackings = Object.keys(gpsTrackings)

    const isInitialPositionsAvailable = selectedDevices.length > 0 && selectedDevices.some(item => item.position)
    const isMarkersAndLinesShown =
      isRealtimeGpsTrackingRunning ||
      !isGpsTrackingsLoading ||
      deviceEIDsWithGpsTrackings.length === 0 && isInitialPositionsAvailable

    const hasAllQueriedDevicesGpsTrackings = queriedDevices.every(device => {
      return gpsTrackings[cleanEID(device.EID)]?.points?.length > 0
    })
    const hasNoneQueriedDevicesGpsTrackings =
      queriedDevices.length > 0 &&
      queriedDevices.every(device => {
        return !(gpsTrackings[cleanEID(device.EID)]?.points?.length > 0)
      })

    const chartPoints = !isGpsTrackingsLoading ? getChartPoints() : []
    const { from, to } = getChartPointLimits(chartPoints)
    const chartData = this.getChartData(chartPoints)

    const showTimelinePanel = false //!isRealtimeGpsTrackingRunning && deviceEIDsWithGpsTrackings.length > 0

    return (
      <div ref={mapContainerRef} className={classes.mapContainer}>
        {!isMapLoaded ? (
          <div className={'col-md-12 ' + classes.loadingWrapper}>
            <LoadingGps />
          </div>
        ) : 
          ''
        }

        <div style={{ height: showTimelinePanel ? `calc(100% - ${timelinePanelContainerHeight}vh)` : '100%' }}>
          <Map
            center={currentPosition}
            className={classes.map}
            defaultCenter={this.defaultCenter}
            onLoad={this.handleMapLoad}
            sat
            zoom={currentZoom}
          >
            {isMarkersAndLinesShown ? (
              <MarkersAndLines
                deviceColors={deviceColors}
                from={from}
                getFilteredAndOrderedGpsPoints={getFilteredAndOrderedGpsPoints}
                gpsTrackings={gpsTrackings}
                isRealtimeGpsTrackingRunning={isRealtimeGpsTrackingRunning}
                onMarkerClick={this.handleMarkerClick}
                queriedDevices={queriedDevices}
                selectedDevices={selectedDevices}
                to={to}
                trail={trail}
              />
            ) : null}

            {this.renderPopup()}
          </Map>
        </div>

        <div style={{ position: 'relative', width: '100%', height: timelinePanelContainerHeight + 'vh' }}>
          <div
            style={{
              bottom: '0px',
              position: 'absolute',
              width: '100%',
              zIndex: 5,
              height: '100%'
            }}
          >
            {!isRealtimeGpsTrackingRunning && !isGpsTrackingsLoading ? 
              hasNoneQueriedDevicesGpsTrackings ? (
                <NoGpsTrackingAlert noData />
              ) : !hasAllQueriedDevicesGpsTrackings ? (
                <NoGpsTrackingAlert />
              ) : null
              : null}
            {showTimelinePanel ? (
              <TimelinePanel
                chartData={chartData}
                chartPoints={chartPoints}
                containerHeight={timelinePanelContainerHeight}
                isReady={!isGpsTrackingsLoading}
                onSetTimelinePanelContainerHeight={this.handleSetTimelinePanelContainerHeight}
                onStepChange={onStepChange}
                step={step}
                trail={trail}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

MapView.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPosition: PropTypes.object.isRequired,
  currentZoom: PropTypes.number.isRequired,
  deviceColors: PropTypes.array.isRequired,
  getChartPointLimits: PropTypes.func.isRequired,
  getChartPoints: PropTypes.func.isRequired,
  getFilteredAndOrderedGpsPoints: PropTypes.func.isRequired,
  gpsTrackings: PropTypes.object.isRequired,
  isGpsTrackingsLoading: PropTypes.bool.isRequired,
  isRealtimeGpsTrackingRunning: PropTypes.bool.isRequired,
  mapContainerRef: PropTypes.object.isRequired,
  onFlyToMarkerClick: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  queriedDevices: PropTypes.array.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  step: PropTypes.number.isRequired,
  trail: PropTypes.number.isRequired
}

export default withStyles(styles)(MapView)
