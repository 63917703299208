import axios from 'axios'
import qs from 'qs'

import * as csNodeActionTypes from 'modules/csNode/actionTypes'

import { createRequestAction } from './actions/utils'
import { AUTH_CLIENT } from './constants'
import instance from './requests'
import * as url from './url'

const Client = {
  requestPasswordReset: email =>
    instance.post(url.requestPasswordReset(), {
      client_id: AUTH_CLIENT,
      email
    }),
  setNewPassword: newPasswordUser =>
    instance.post(url.setNewPassword(newPasswordUser.hashId, newPasswordUser.token), {
      newPassword: newPasswordUser.password
    }),
  getToken: groupId => instance.get(url.token(groupId)),
  getNodeToken: nodeId => instance.get(url.nodeToken(nodeId)),
  getRoles: () => instance.get(url.roles()),
  getRolesHierarchy: () => instance.get(url.rolesHierarchy()),
  getRole: roleId => instance.get(url.role(roleId)),
  newRole: newRole => instance.post(url.roles(), newRole),
  editRole: newRole =>
    instance.put(url.role(newRole.hashId), newRole, {
      headers: {
        'if-match': newRole.version
      }
    }),
  deleteRole: (roleId, roleVersion) =>
    instance.delete(url.role(roleId), {
      headers: {
        'if-match': roleVersion
      }
    }),

  getPrivileges: () => instance.get(url.privileges()),

  getGroups: () => instance.get(url.groups()),
  getGroup: groupId => instance.get(url.group(groupId)),
  newGroup: newGroup => instance.post(url.groups(), newGroup),
  editGroup: newGroup =>
    instance.put(url.group(newGroup.hashId), newGroup, {
      headers: {
        'if-match': newGroup.version
      }
    }),
  deleteGroup: (groupId, groupVersion) =>
    instance.delete(url.group(groupId), {
      headers: {
        'if-match': groupVersion
      }
    }),

  getGroupNodes: groupId => instance.get(url.groupNodes(groupId)),
  getGroupNodesWithQuery: (groupId, query) => instance.get(url.groupNodesWithQuery(groupId, query)),
  getGroupNodesPaginated: (groupId, page, size) => instance.get(url.groupNodesPaginated(groupId, page - 1, size)),
  deleteGroupNodes: (groupId, nodes) => instance.post(url.deleteGroupNodes(groupId), nodes),
  getGroupUnassignedNodes: (groupId, query) => instance.get(url.groupUnassignedNodes(groupId, query)),
  assignGroupNodes: (groupId, nodes) => instance.post(url.groupNodes(groupId), nodes),

  /*getGeofences: (groupId, query) => instance.get(url.geofences(groupId, query)),
  getGeofence: geofenceId => instance.get(url.geofence(geofenceId)),
  newGeofence: (groupId, newGeofence) => instance.post(url.geofences(groupId), newGeofence),
  editGeofence: newGeofence =>
    instance.put(url.geofence(newGeofence.hashId), newGeofence, {
      headers: {
        'if-match': newGeofence.version
      }
    }),
  deleteGeofence: (geofenceId, geofenceVersion) =>
    instance.delete(url.geofence(geofenceId), {
      headers: {
        'if-match': geofenceVersion
      }
    }),*/

  getUsers: query => instance.get(url.users(query)),
  getUser: userId => instance.get(url.user(userId)),
  newUser: newUser => instance.post(url.users(), newUser),
  editUser: newUser =>
    instance.put(url.user(newUser.hashId), newUser, {
      headers: {
        'if-match': newUser.version
      }
    }),
  deleteUser: (userId, userVersion) =>
    instance.delete(url.user(userId), {
      headers: {
        'if-match': userVersion
      }
    }),

  getPlants: () => instance.get(url.plants()),
  newNode: newNode => instance.post(url.nodes(), newNode),
  editNode: newNode =>
    instance.put(url.node(newNode.hashId), newNode, {
      headers: {
        'if-match': newNode.version
      }
    }),
  deleteNode: (nodeId, nodeVersion) =>
    instance.delete(url.node(nodeId), {
      headers: {
        'if-match': nodeVersion
      }
    }),

  getUid: nodeId => instance.post(url.nodeUid(nodeId)).then(({ data }) => data[0].uid),

  getActions: nodeType => instance.get(url.actions(nodeType)),
  newTasks: (actionId, tasks) => instance.post(url.tasks(actionId), tasks),

  getTasks: query => instance.get(url.getTasks(query)),

  downloadTask: tasksHashid => instance.get(url.downloadTask(tasksHashid)),

  downloadTaskFile: task => instance.get(url.downloadTaskFile(task)),

  cancelTask: tasksHashid => instance.put(url.cancelTask(tasksHashid)),

  relaunchTask: tasksHashid => instance.post(url.relaunchTask(tasksHashid)),

  getNotifications: (groupId, query) => instance.get(url.getNotifications(groupId, query)),
  getNotificationsEvents: notificationId => instance.get(url.notificationsEvents(notificationId)),
  getNotification: (notificationId, groupId) => instance.get(url.notification(notificationId, groupId)),
  modifyNotificacion: (notificationToUpdate, groupId) =>
    instance.put(url.notification(notificationToUpdate.hashId, groupId), notificationToUpdate, {
      headers: {
        'if-match': notificationToUpdate.version
      }
    }),
  searchNotificationsByDeviceEIDs: devicesEIDs => {
    let queryString = ''
    if (devicesEIDs.length > 0) {
      queryString += 'source=in=(' + devicesEIDs.map(eid => `'${eid}'`).join(',') + ')'
    }

    const requestAction = createRequestAction(
      csNodeActionTypes.CS_NODES_NOTIFICATIONS,
      url.notificationsSearch(),
      'post',
      queryString
    )
    requestAction.payload.request.headers = {
      'Content-Type': 'application/json'
    }
    return requestAction
  },

  //Accciones, tipos de notificaciones, a asociar: email, push, bell, ...
  getNotificationsActions: groupId => instance.get(url.getNotificationsActions(groupId)),

  //For Notifications settings
  getRules: (groupId, query) => instance.get(url.rules(groupId, query)),
  getRule: ruleHashId => instance.get(url.rule(ruleHashId)),
  getRulesInstances: (groupId, query) => instance.get(url.getRuleInstances(groupId, query)),
  getRuleInstance: ruleInstanceId => instance.get(url.ruleInstance(ruleInstanceId)),
  newRuleInstance: newRuleInstance => instance.post(url.rulesInstances(), newRuleInstance),
  deleteRuleInstance: (ruleInstanceHashId, ruleInstanceVersion) =>
    instance.delete(url.ruleInstance(ruleInstanceHashId), {
      headers: {
        'if-match': ruleInstanceVersion
      }
    }),
  modifyRuleInstance: ruleInstanceToUpdate =>
    instance.put(url.ruleInstance(ruleInstanceToUpdate.hashId), ruleInstanceToUpdate, {
      headers: {
        'if-match': ruleInstanceToUpdate.version
      }
    }),
  assignDevicesToRuleInstance: (ruleInstanceHashId, nodesData) =>
    instance.post(url.ruleInstanceAssignDevices(ruleInstanceHashId), nodesData),
  assignUsersToRuleInstance: (ruleInstanceHashId, usersData) =>
    instance.post(url.ruleInstanceAssignUsers(ruleInstanceHashId), usersData),
  desassignDevicesToRuleInstance: (ruleInstanceHashId, nodesData) =>
    instance.post(url.ruleInstanceDesassignDevices(ruleInstanceHashId), nodesData),
  desassignUsersToRuleInstance: (ruleInstanceHashId, usersData) =>
    instance.post(url.ruleInstanceDesassignUsers(ruleInstanceHashId), usersData),
  getRuleInstanceDevicesAssigned: (ruleInstanceId, query) =>
    instance.get(url.ruleInstanceDevicesAssigned(ruleInstanceId, query)),
  getRuleInstanceProductsAssigned: (ruleInstanceId, query) =>
    instance.get(url.ruleInstanceProductsAssigned(ruleInstanceId, query)),
  getRuleInstanceUsersAssigned: (ruleInstanceId, query) =>
    instance.get(url.ruleInstanceUsersAssigned(ruleInstanceId, query)),
  getRuleInstanceUser: (ruleInstanceId, groupId, email) =>
    instance.get(url.getRuleInstanceUser(ruleInstanceId, groupId, email)),

  assignNotificationsActionsToRuleInstanceAssignedUsers: (ruleInstanceHashId, notificationActions) =>
    instance.post(url.ruleInstanceAssignNotificationsActionsToAssignedUsers(ruleInstanceHashId), notificationActions),
  unassignNotificationsActionsToRuleInstanceAssignedUsers: (ruleInstanceHashId, notificationActions) =>
    instance.post(url.ruleInstanceUnassignNotificationsActionsToAssignedUsers(ruleInstanceHashId), notificationActions),
  removeUserFromAllAssignedRuleInstances: (groupId, email) =>
    instance.delete(url.removeUserFromAllRuleInstances(groupId, email)),

  getComponent: (nodeId, componentId) => {
    const promise = instance.get(url.node(nodeId))

    const componentPromise = promise.then(response => {
      const theComponent = response.data.components.filter(component => component.id === componentId)
      return theComponent[0]
    })

    return componentPromise
  },
  newComponent: (nodeId, newComponent) => instance.post(url.componentLocation(nodeId, newComponent.type), newComponent),
  editComponent: (nodeId, component, newComponent) =>
    instance.put(url.componentToActLocation(nodeId, component.id, component.type), newComponent),
  deleteComponent: (nodeId, componentId, componentType) =>
    instance.delete(url.componentToActLocation(nodeId, componentId, componentType)),
  getManufacturer: manufacturerId => instance.get(url.manufacturer(manufacturerId)),
  getManufacturers: () => instance.get(url.manufacturers()),
  // eslint-disable-next-line max-params
  searchData: (nodeId, minDate, maxDate, page = 0, filterBy = null) =>
    instance.get(url.historicQuery(nodeId, minDate, maxDate, page, filterBy)),

  sendToken: (hashId, deviceToken) =>
    instance.post(url.sendToken(hashId), deviceToken, {
      headers: {
        'Content-Type': 'text/plain'
      }
    }),

  // eslint-disable-next-line max-params
  getDatedAzureLogs: (deviceEid, start, end, sortOrder, size, filters) =>
    instance.get(url.getDatedAzureLogs(deviceEid, start, end, sortOrder, size, filters)),
  // eslint-disable-next-line max-params
  getAggregatedAzureLogs: (deviceEid, start, end, sortOrder, page, filters) =>
    instance.get(url.getAggregatedAzureLogs(deviceEid, start, end, sortOrder, page, filters)),

  // DEVICE DASHBOARDS
  getNodeDashboards: nodeId => instance.get(url.nodeDashboards(nodeId)),
  newDashboard: dashboard => instance.post(url.newDashboard(), dashboard),
  newDashboardSetup: (nodeHashId, dashboard) => instance.post(url.newDashboardSetup(nodeHashId), dashboard),
  editDashboard: dashboard =>
    instance.put(url.editDashboard(dashboard.hashId), dashboard, {
      headers: {
        'if-match': dashboard.version
      }
    }),
  updateDashboard: (dashboardHashId, dashboard) => instance.post(url.updateDashboard(dashboardHashId), dashboard),
  deleteDashboard: (dashboard, version) =>
    instance.delete(url.deleteDashboard(dashboard), {
      headers: {
        'if-match': version
      }
    }),
  getDashboardWidgets: dashboardId => instance.get(url.getDashboardWidgets(dashboardId)),

  // GROUP DASHBOARDS
  getGroupDashboards: selectedGroup => instance.get(url.groupDashboards(selectedGroup)),
  newGroupDashboard: dashboard => instance.post(url.newGroupDashboard(), dashboard),
  newGroupDashboardSetup: (dashboardHashId, dashboard) =>
    instance.post(url.newGroupDashboardSetup(dashboardHashId), dashboard),
  editGroupDashboard: dashboard =>
    instance.put(url.editGroupDashboard(dashboard.hashId), dashboard, {
      headers: {
        'if-match': dashboard.version
      }
    }),
  deleteGroupDashboard: (dashboard, version) =>
    instance.delete(url.deleteGroupDashboard(dashboard), {
      headers: {
        'if-match': version
      }
    }),
  updateGroupDashboard: (dashboardId, dashboard) => instance.post(url.updateGroupDashboard(dashboardId), dashboard),
  getGroupDashboardWidgets: dashboardId => instance.get(url.getGroupDashboardWidgets(dashboardId)),
  uploadDashboards: dashboards => instance.post(url.uploadDashboards(), dashboards, { noGroupIdNeeded: true }),
  deleteDashboards: (groupId, devices) => instance.delete(url.deleteDashboards(groupId), { data: devices }),

  // eslint-disable-next-line max-params
  getAzureLocations: (deviceEid, start, end, page, sortOrder) =>
    instance.get(url.gpsTrackings(deviceEid, start, end, page, sortOrder)),
  // eslint-disable-next-line max-params
  getAggregatedGpsLocations: (deviceEid, groupId, start, end, size, page, sortOrder, aggLevel) =>
    instance.get(url.nodeAggregatedGPSLocations(deviceEid, groupId, start, end, size, page, sortOrder, aggLevel)),

  getGroupTheme: groupId => instance.get(url.theme(groupId)),
  createGroupTheme: (groupId, theme) => instance.post(url.theme(groupId), theme),
  updateGroupTheme: (groupId, theme, themeId) => instance.put(url.theme(groupId, themeId), theme),
  deleteteGroupTheme: (groupId, themeId) => instance.delete(url.theme(groupId, themeId)),
  getGroupThemeByLoginPath: loginPath => instance.get(url.loginPathTheme(loginPath)),
  getGroupThemeConfigUrl: (groupId, themeId) => instance.get(url.themeConfig(groupId, themeId), { noAuthNeeded: true }),
  updateGroupThemeConfig: (groupId, themeId, theme) => instance.post(url.themeConfig(groupId, themeId), theme),

  getGroupThemeConfig: configUrl =>
    axios({
      method: 'GET',
      url: configUrl
    }),
  getLastKnownSignals: (groupId, eids, signals) => instance.get(url.getLastKnownSignals(groupId, eids, signals)),
  getLastDM1s: (groupId, eids) => instance.get(url.getLastDM1s(groupId, eids)),

  getMeasurements: (groupId, deviceEids) => instance.get(url.getMeasurements(groupId, deviceEids)),
  getOneMeasurement: (groupId, measurementId) => instance.get(url.getOneMeasurement(groupId, measurementId)),
  getMeasurementsDevices: (groupId, measurementConfigHashId) =>
    instance.get(url.getMeasurementsDevices(groupId, measurementConfigHashId)),
  getMeasurementsResults: (hashId, groupId, min, max) =>
    instance.get(url.measurmentsResults(hashId, groupId, min, max)),
  newMeasurements: (groupId, measurements) => instance.post(url.newMeasurements(groupId), measurements),
  updateMeasurement: (hashId, groupId, measurement) => instance.put(url.measurement(hashId, groupId), measurement),
  deleteMeasurement: (hashId, groupId) => instance.delete(url.measurement(hashId, groupId)),
  importMeasurements: (groupId, measurements) => instance.post(url.importMeasurements(groupId), measurements),

  getMaintenances: ({ groupId, page, size, sort, order, filter }) =>
    instance.get(url.getMaintenances({ groupId, page, size, sort, order, filter })),
  getOneMaintenance: (groupId, maintenanceId) => instance.get(url.getOneMaintenance({ groupId, maintenanceId })),
  newMaintenance: (groupId, maintenanceData) => instance.post(url.getMaintenances({ groupId }), maintenanceData),
  updateOneMaintenance: ({ groupId, maintenanceId, maintenanceData, rescheduleInspections }) =>
    instance.put(url.getOneMaintenance({ groupId, maintenanceId, rescheduleInspections }), maintenanceData),
  deleteMaintenance: (groupId, maintenanceId) => instance.delete(url.getOneMaintenance({ groupId, maintenanceId })),
  getOneMaintenanceInspections: ({ groupId, maintenanceId, deviceId, page, size, sort, order, filter }) =>
    instance.get(
      url.getOneMaintenanceInspections({ groupId, maintenanceId, deviceId, page, size, sort, order, filter })
    ),
  newOneMaintenanceInpection: ({ groupId, maintenanceId, inspectionData }) =>
    instance.post(url.getOneMaintenanceInspections({ groupId, maintenanceId }), inspectionData),
  updateOneMaintenceInspections: ({ groupId, maintenanceId, inspectionData }) =>
    instance.put(url.getOneMaintenanceInspections({ groupId, maintenanceId }), inspectionData),
  getOneMaintenanceInspectionsLimited: ({ groupId, maintenanceId, page, size, sort, order, filter }) =>
    instance.get(url.getOneMaintenanceInspectionsLimited({ groupId, maintenanceId, page, size, sort, order, filter })),
  getMobileSignals: ({ groupId, eids }) => instance.get(url.getMobileSignals({ groupId, eids })),
  getDevicesLastGPSLocation: ({ eids, page, size }) => instance.get(url.nodesLastGPSLocation({ eids, page, size }))
}

export default Client
